import axios from 'axios';
import { appConfig } from '../config';

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = appConfig.API_URL;

// Request Interceptor. All Request pass from here
axiosInstance.interceptors.request.use(
  (axiosConfig) => {
    const authToken = localStorage.getItem('accessToken');
    if (authToken) {
      axiosConfig.headers.Authorization = `Bearer ${authToken}`;
    }
    if (axiosConfig.rawRequest) {
      // header for file_upload, video/audio will handle here i.e raw requests
    } else {
      axiosConfig.headers['Content-Type'] = 'application/json';
    }
    return axiosConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

/*
  Response Interceptor
  Responsibilities:
  1- If api sends 401 token then send user to login page
*/
axiosInstance.interceptors.response.use(
  (response) => response

  //   function (error) {
  //     if (get(error, 'response.status', '') === 401) {
  //       // un-authenticated user! clear storage and take user to Login page.
  //       logoutUserHandler();
  //     } else {
  //       return Promise.reject(error);
  //     }
  //   }
);

export default axiosInstance;
