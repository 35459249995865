import { createContext, useState } from 'react';

export const QueriesHistory = createContext({
  newChat: false,
  chatTitle: false,
  contextChatId: null,
  firstRender: false,
});

function QueriesHistoryProvider({ children }) {
  const chatID = localStorage.getItem('chatId');
  const [newChat, setNewChat] = useState(0);
  const [chatTitle, setChatTitle] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [chatId, setChatId] = useState(chatID ? Number(chatID) : null);
  const [firstChatId, setFirstChatId] = useState(null);
  const resetQueryHistory = () => {
    removeChatData();
    setNewChat((prev) => prev + 1);
    setChatTitle(false);
    setFirstRender(true);
    setFirstChatId(null);
  };

  const removeChatData = () => {
    localStorage.removeItem('chatId');
    setChatId(null);
    setFirstChatId(null);
  };

  const newChatHandler = () => {
    removeChatData();
    setFirstRender(true);
    setNewChat((prev) => prev + 1);
  };

  const setChatTitleHandler = () => {
    setChatTitle((prev) => !prev);
  };

  const setChatIdHandler = (id) => {
    setFirstRender(true);
    setFirstChatId(null);
    setChatId(id);
    localStorage.setItem('chatId', id);
  };

  const setFirstChatIdHandler = (id) => {
    setFirstChatId(id);
  };

  const handleFirstRenderer = (payload) => {
    setFirstRender(payload);
  };

  const value = {
    newChat,
    chatTitle,
    contextChatId: chatId,
    firstRender,
    firstChatId,
    resetQueryHistory,
    removeChatData,
    newChatHandler,
    handleFirstRenderer,
    setFirstChatIdHandler,
    setChatTitleHandler,
    setChatIdHandler,
  };
  return <QueriesHistory.Provider value={value}>{children}</QueriesHistory.Provider>;
}

export default QueriesHistoryProvider;
