import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Drawer,
  Typography,
  Stack,
  Divider,
  LogoutIcon,
  SettingsIcon,
  AdminPanelSettingsIcon,
  Button,
} from '../../../mui';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
import { QueriesHistory } from '../../../store/QueriesHistory';
import AdminDialog from '../../../pages/AdminDialog';

const NAV_WIDTH = 280;

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const queryData = useContext(QueriesHistory);

  const isDesktop = useResponsive('up', 'lg');
  const logoutHandler = () => {
    localStorage.clear();
    queryData.resetQueryHistory();
    navigate('/login');
  };
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
  const [openDialog, setOpenDialog] = useState({ dialogName: '', status: false });

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box
          component="img"
          alt="logo"
          src="assets/logo.png"
          sx={{ maxWidth: 200, height: '100%', borderRadius: 1.5, flexShrink: 0 }}
        />
      </Box>

      <Box sx={{ mb: 3, mx: 2.5 }}>
        <Button variant="contained" fullWidth onClick={() => queryData.newChatHandler()} sx={{ boxShadow: 0 }}>
          New Chat
        </Button>
      </Box>

      <NavSection />

      <Box sx={{ flexGrow: 1 }} />

      <Divider sx={{ borderBottomWidth: 2 }} />

      <Stack direction="column" spacing={2} sx={{ px: 2.5, py: 2, pb: 10, mt: 2 }}>
        {/* <Stack
          onClick={() => setOpenDialog({ dialogName: 'settings', status: true })}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          spacing={3}
        >
          <SettingsIcon sx={{ color: '#A4A4A4', fontSize: 18 }} />
          <Typography sx={{ color: '#A4A4A4', fontWeight: '500', fontSize: 14 }}>Settings</Typography>
        </Stack>
        <Stack
          onClick={() => setOpenDialog({ dialogName: 'admin', status: true })}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          spacing={3}
        >
          <AdminPanelSettingsIcon sx={{ color: '#A4A4A4', fontSize: 18 }} />
          <Typography sx={{ color: '#A4A4A4', fontWeight: '500', fontSize: 14 }}>Admin</Typography>
        </Stack> */}
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          spacing={3}
          onClick={logoutHandler}
        >
          <LogoutIcon sx={{ color: '#A4A4A4', fontSize: 18 }} />
          <Typography sx={{ color: '#A4A4A4', fontWeight: '500', fontSize: 14 }}>Log Out</Typography>
        </Stack>
      </Stack>
      {/* {openDialog.dialogName === 'admin' ? (
        <AdminDialog
          openDialog={openDialog.status}
          setOpenDialog={() => setOpenDialog({ dialogName: '', status: false })}
        />
      ) : null} */}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
