import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { PopupButton } from 'react-calendly';
import { Box, Button, Dialog, DialogContent, Grid, Typography } from '../mui';

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    position: 'relative',
    display: 'inline-block',
    width: '40%',

    padding: '0 20px',
  },
  image: {
    display: 'block',
    width: '100%',
    height: 'auto',
    transition: 'opacity 0.3s, border-color 0.3s',
    border: 'none',
    opacity: 1,
    cursor: 'pointer',
  },
  buttonStyle: {
    borderWidth: '0.15rem',
    borderStyle: 'solid',
    borderColor: `${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    borderRadius: theme.shape.borderRadius,
    padding: '6px 16px',
    cursor: 'pointer',
    transition: 'all 0.2s ',
    textDecoration: 'none',
    fontSize: '1rem',
    fontWeight: '700',
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    '&:.active': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
}));

const AdminDialog = ({ openDialog, setOpenDialog }) => {
  const handleClose = () => {
    setOpenDialog(null);
  };
  const classes = useStyles();

  const [openImage, setOpenImage] = useState(null);
  return (
    <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      PaperProps={{ sx: { height: '70%', width: '100%', padding: 0, overflow: 'hidden' } }}
    >
      <DialogContent sx={{ padding: 0, m: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" flexWrap="wrap" justifyContent="center" my={6}>
              <Typography
                variant="p"
                style={{ fontWeight: '600', width: '100%', textAlign: 'center', marginBottom: '25px' }}
              >
                Admin panel screens
              </Typography>
              <Box className={`${classes.imageContainer}`} onClick={() => setOpenImage('customers')}>
                <img className={classes.image} src="assets/admin/1.png" alt="customers" />
              </Box>
              <Box className={`${classes.imageContainer}`} onClick={() => setOpenImage('pdf')}>
                <img className={classes.image} src="assets/admin/2.png" alt="PDF" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" flexDirection="row" justifyContent="center" my={2}>
              <Button variant="contained" sx={{ mx: 4 }}>
                Request to grant access
              </Button>
              {/* <Button variant="outlined" sx={{ mx: 4 }}>
                Schedual a metting
              </Button> */}
              <PopupButton
                url="https://calendly.com/d/z5s-swf-x26/gptglow-demo"
                className={classes.buttonStyle}
                rootElement={document.getElementById('root')}
                text="Schedule A Metting"
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      {openImage === 'customers' ? (
        <Dialog
          open={openDialog}
          onClose={() => setOpenImage(null)}
          fullWidth
          maxWidth="md"
          PaperProps={{ sx: { width: '65vw', padding: 0, overflow: 'hidden' } }}
        >
          <DialogContent sx={{ padding: 0, m: 0 }}>
            <img src="assets/admin/1.png" alt="customers" />
          </DialogContent>
        </Dialog>
      ) : openImage === 'pdf' ? (
        <Dialog
          open={openDialog}
          onClose={() => setOpenImage(null)}
          fullWidth
          maxWidth="md"
          PaperProps={{ sx: { width: '65vw', padding: 0, overflow: 'hidden' } }}
        >
          <DialogContent sx={{ padding: 0, m: 0 }}>
            <img src="assets/admin/2.png" alt="PDF" />
          </DialogContent>
        </Dialog>
      ) : null}
    </Dialog>
  );
};

export default AdminDialog;
