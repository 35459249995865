import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Suspense } from 'react';
import Router from './routes';
import ThemeProvider from './theme';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { CircularProgress } from './mui';
import QueriesHistoryProvider from './store/QueriesHistory';

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider>
          <ScrollToTop />
          <StyledChart />
          <Suspense
            fallback={
              <div
                style={{
                  minHeight: '100vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </div>
            }
          >
            <QueriesHistoryProvider>
              <Router />
            </QueriesHistoryProvider>
          </Suspense>
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
}
